import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { type ExportData } from "@common/interfaces/exportData/exportData";

type InitialState = {
  data: ExportData;
};

type Payload = PayloadAction<Partial<ExportData>>;

export const initialState: InitialState = {
  data: {
    recipients: [],
    isSubzonesIncluded: true,
  },
};

export const exportDataSlice = createSlice({
  name: "exportData",
  initialState,
  reducers: {
    clearExportDataState: () => initialState,
    updateExportData: (state, { payload }: Payload) => {
      state.data = {
        ...state.data,
        ...payload,
      };
    },
    setExportData: (state, { payload }: Payload) => {
      state.data = {
        ...initialState.data,
        ...payload,
      };
    },
  },
});

export const { updateExportData, setExportData, clearExportDataState } = exportDataSlice.actions;
export const exportDataReducer = exportDataSlice.reducer;
