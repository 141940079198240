import { type Dispatch } from "@reduxjs/toolkit";
import isEqual from "lodash/isEqual";

import { api } from "@api/apollo";

import {
  queryTranslationDetails,
  type QueryTranslationDetailsResult,
  queryTranslations,
  type QueryTranslationsResult,
} from "@services/translationService";

import { type RootState } from "@store/rootReducer";

import { addTranslationsDetails, addTranslationsInfo } from "./translation.slice";

export const getTranslationDetails =
  () =>
  async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
    const { version: oldVersion, allowedLanguages } = getState().translation;

    const result = await api.client?.query<QueryTranslationDetailsResult>({
      query: queryTranslationDetails,
    });

    if (
      result &&
      (!isEqual(result.data.translationDetailsByProject.version, oldVersion) ||
        !isEqual(result.data.translationDetailsByProject.allowedLanguages, allowedLanguages))
    ) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await getTranslations(dispatch);

      dispatch(
        addTranslationsDetails({
          version: result.data.translationDetailsByProject.version,
          allowedLanguages: result.data.translationDetailsByProject.allowedLanguages,
        }),
      );
    }
  };

const getTranslations = async (dispatch: Dispatch): Promise<void> => {
  const result = await api.client?.query<QueryTranslationsResult>({
    query: queryTranslations,
  });

  if (result && result.data.translationsByProject) {
    dispatch(
      addTranslationsInfo({
        translations: result.data.translationsByProject,
      }),
    );
  }
};
