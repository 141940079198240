import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import isEmpty from "lodash/isEmpty";
import { REHYDRATE, type RehydrateAction } from "redux-persist";

import { addTranslations } from "@lang/i18n";

import { type RootState } from "@store/rootReducer";

export interface TranslationState {
  allowedLanguages: string[] | null;
  translations: object | null;
  version: string | null;
}

export const initialState: TranslationState = {
  version: null,
  translations: null,
  allowedLanguages: null,
};

export const translationSlice = createSlice({
  name: "translation",
  initialState,
  reducers: {
    addTranslationsInfo: (state, { payload: { translations } }: PayloadAction<{ translations: object }>) => {
      if (!isEmpty(translations)) {
        addTranslations(translations);
      }
      return { ...state, translations };
    },
    addTranslationsDetails: (
      state,
      { payload: { version, allowedLanguages } }: PayloadAction<{ allowedLanguages: string[]; version: string }>,
    ) => ({ ...state, version, allowedLanguages }),
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state: TranslationState = initialState, action: RehydrateAction) => {
      const payload = action.payload as RootState;

      if (payload && payload.translation && payload.translation.version && payload.translation.translations) {
        payload.translation.translations && addTranslations(payload.translation.translations);
        return { ...state, ...payload.translation };
      }
      return state;
    });
  },
});

export const { addTranslationsDetails, addTranslationsInfo } = translationSlice.actions;
export const translationReducer = translationSlice.reducer;
