import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type ToastMessageV2, ToastType } from "@technis/ui";

import { getRandomId } from "@common/helpers";

const MAX_TOAST_COUNT = 5;

export interface ToastState {
  toasts: ToastMessageV2[];
}

export const initialState: ToastState = {
  toasts: [],
};

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    displayToast: (state, { payload }: PayloadAction<Omit<ToastMessageV2, "id">>) => ({
      ...state,
      toasts: state.toasts.length < MAX_TOAST_COUNT ? [...state.toasts, { id: getRandomId(), ...payload }] : [...state.toasts],
    }),
    closeToast: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      toasts: state.toasts.filter((toast) => toast.id != payload),
    }),
    displayToastError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      toasts: [
        ...state.toasts,
        {
          id: getRandomId(),
          text: payload,
          type: ToastType.ERROR,
        },
      ],
    }),
    displayToastSuccess: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      toasts: [
        ...state.toasts,
        {
          id: getRandomId(),
          text: payload,
          type: ToastType.SUCCESS,
        },
      ],
    }),
  },
});

export const { displayToastSuccess, displayToast, closeToast, displayToastError } = toastSlice.actions;
export const toastReducer = toastSlice.reducer;
