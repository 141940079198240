import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { DateFormats } from "@technis/shared";
import { ThemeType } from "@technis/ui";

import { OnboardingComponentName } from "@common/enums";
import { OnboardingStepKey as OnboardingStepKey } from "@common/enums/onboarding";
import { type UserData } from "@common/interfaces";
import { type OnboardingStep, type UpdateUserDataArguments } from "@common/interfaces/onboarding";

import { RoutePath } from "@routes/routePath";

interface UserOnboardingState {
  steps: OnboardingStep[];
  token: string;
  userData: UserData;
}

interface UpdateConfirmButtonStateArguments {
  currentStepIndex: number;
  isConfirmButtonActive: boolean;
}

interface UpdateValidationStateArguments {
  currentStepIndex: number;
  isDataValid: boolean;
}

const caption = {
  organizationSettings: {
    translationKey: OnboardingStepKey.ORGANIZATION_SETTINGS,
  },
  about: {
    translationKey: OnboardingStepKey.ABOUT,
  },
  password: {
    translationKey: OnboardingStepKey.PASSWORD,
  },
  profileSettings: {
    translationKey: OnboardingStepKey.PROFILE_SETTINGS,
  },
  security: {
    translationKey: OnboardingStepKey.SECURITY,
  },
};

export const initialOnboardingSteps = [
  {
    hasRequiredFields: true,
    translationKey: caption.organizationSettings.translationKey,
    isConfirmButtonActive: false,
    routePath: RoutePath.ONBOARDING_ORG_SETTINGS,
    isPrimaryUser: true,
    componentName: OnboardingComponentName.ORGANIZATION_SETTINGS,
  },
  {
    hasRequiredFields: true,
    translationKey: caption.about.translationKey,
    isConfirmButtonActive: false,
    routePath: RoutePath.ONBOARDING_ABOUT,
    componentName: OnboardingComponentName.ABOUT_YOU,
  },
  {
    hasRequiredFields: true,
    translationKey: caption.password.translationKey,
    isConfirmButtonActive: false,
    routePath: RoutePath.ONBOARDING_PASSWORD,
    componentName: OnboardingComponentName.CREATE_PASSWORD,
  },
  {
    hasRequiredFields: false,
    translationKey: caption.profileSettings.translationKey,
    isConfirmButtonActive: true,
    routePath: RoutePath.ONBOARDING_PROFILE_SETTINGS,
    componentName: OnboardingComponentName.PROFILE_SETTINGS,
  },
  {
    hasRequiredFields: false,
    translationKey: caption.security.translationKey,
    isConfirmButtonActive: true,
    routePath: RoutePath.ONBOARDING_SECURITY,
    componentName: OnboardingComponentName.SECURITY,
    isLastForm: true,
  },
];

export const initialUserData: UserData = {
  passwordNew: "",
  passwordConfirmation: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  twoFactorVerificationMethod: undefined,
  userImage: "",
  organizationLogo: "",
  organizationName: "",
  organizationAddress: "",
  language: "fr",
  timezone: "UTC",
  theme: ThemeType.LIGHT,
  dateFormat: DateFormats.DD_MM_YYYY,
  timeFormat: "00 h 00",
  countryCode: "",
  countryISO: "",
};

export const initialState: UserOnboardingState = {
  userData: {
    ...initialUserData,
  },
  steps: initialOnboardingSteps.map((step) => ({ ...step })),
  token: "",
};

const userOnboardingSlice = createSlice({
  name: "userOnboarding",
  initialState,
  reducers: {
    updateOnboardingState: (state, { payload }: PayloadAction<UserOnboardingState>) => {
      const { userData, steps, token } = payload;
      return {
        ...state,
        steps: steps.map((step) => ({ ...step })),
        userData,
        token,
      };
    },
    updateConfirmButtonState: (state, { payload }: PayloadAction<UpdateConfirmButtonStateArguments>) => {
      const { isConfirmButtonActive, currentStepIndex } = payload;
      return {
        ...state,
        steps: {
          ...state.steps,
          [currentStepIndex]: {
            ...state.steps[currentStepIndex],
            isConfirmButtonActive,
          },
        },
      };
    },
    updateValidationState: (state, { payload }: PayloadAction<UpdateValidationStateArguments>) => {
      const { isDataValid, currentStepIndex } = payload;
      return {
        ...state,
        steps: {
          ...state.steps,
          [currentStepIndex]: {
            ...state.steps[currentStepIndex],
            isDataValid,
          },
        },
      };
    },
    updateUserData: (state, { payload }: PayloadAction<UpdateUserDataArguments>) => {
      const { propName, value } = payload;
      return {
        ...state,
        userData: {
          ...state.userData,
          [propName]: value,
        },
      };
    },
  },
});

export const { updateConfirmButtonState, updateValidationState, updateUserData, updateOnboardingState } = userOnboardingSlice.actions;
export const userOnboardingReducer = userOnboardingSlice.reducer;
export { UserOnboardingState };
