import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { defaultContacts } from "@common/constants/alert/defaultContacts";
import { AlertDetail } from "@common/enums";
import { type AlertCondition, type AlertEvent, type AlertNotification, type AlertNotificationContacts } from "@common/interfaces/alert";

import { getInitialNotificationData } from "@components/Settings/Alert/AlertDetails/Notifications/common";

export interface AlertInitialState {
  [AlertDetail.ID]?: number;
  [AlertDetail.NOTIFICATIONS]: AlertNotification[];
  [AlertDetail.CONDITION]: AlertCondition;
  [AlertDetail.TITLE]: string;
  [AlertDetail.EVENT]?: AlertEvent;
  [AlertDetail.PLANNING_ID]?: number;
  [AlertDetail.INSTALLATION_ID]?: number;
  [AlertDetail.NOTIFICATION_CONTACTS]: AlertNotificationContacts;
}

export const initialState: AlertInitialState = {
  [AlertDetail.ID]: undefined,
  [AlertDetail.NOTIFICATIONS]: [getInitialNotificationData()],
  [AlertDetail.CONDITION]: { type: undefined },
  [AlertDetail.TITLE]: "",
  [AlertDetail.EVENT]: undefined,
  [AlertDetail.INSTALLATION_ID]: undefined,
  [AlertDetail.PLANNING_ID]: undefined,
  [AlertDetail.NOTIFICATION_CONTACTS]: defaultContacts,
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    updateAlert: (
      state,
      {
        payload,
      }: PayloadAction<{
        data: AlertInitialState[AlertDetail];
        field: AlertDetail;
      }>,
    ) => {
      const { field, data } = payload;
      return { ...state, [field]: data };
    },
    updateAlertDetails: (
      state,
      {
        payload,
      }: PayloadAction<{
        condition: AlertCondition;
        event: AlertEvent;
        id?: number;
        installationId?: number;
        title: string;
      }>,
    ) => {
      const { condition, event, installationId, title, id } = payload;

      return {
        ...state,
        [AlertDetail.CONDITION]: condition,
        [AlertDetail.EVENT]: event,
        [AlertDetail.TITLE]: title,
        [AlertDetail.ID]: id,
        [AlertDetail.INSTALLATION_ID]: installationId,
      };
    },
    updateAlertNotifications: (
      state,
      { payload }: PayloadAction<{ contacts: AlertNotificationContacts; notifications: AlertNotification[]; planningId?: number }>,
    ) => {
      const { notifications, planningId, contacts } = payload;
      return {
        ...state,
        [AlertDetail.NOTIFICATIONS]: notifications,
        [AlertDetail.PLANNING_ID]: planningId,
        [AlertDetail.NOTIFICATION_CONTACTS]: contacts,
      };
    },
    clearAlertDetails: () => initialState,
  },
});

export const { updateAlert, clearAlertDetails, updateAlertDetails, updateAlertNotifications } = alertSlice.actions;
export const alertReducer = alertSlice.reducer;
