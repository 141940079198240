import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
  isExitModalRendered?: boolean;
  isExitModalShown?: boolean;
  navigationPath?: string;
};

export const initialState: InitialState = {
  isExitModalShown: false,
  isExitModalRendered: false,
};

export const exitModalSlice = createSlice({
  name: "exitModal",
  initialState,
  reducers: {
    updateExitModalState: (state, { payload }: PayloadAction<InitialState>) => ({ ...state, ...payload }),
  },
});

export const { updateExitModalState } = exitModalSlice.actions;
export const exitModalReducer = exitModalSlice.reducer;
