import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type Organization } from "@technis/shared";

type OrganizationSlice = Partial<Pick<Organization, "name" | "logo">>;
export const initialState: OrganizationSlice = {};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    updateOrganizationData: (state, { payload }: PayloadAction<OrganizationSlice>) => ({
      ...state,
      ...payload,
    }),
  },
});

export const { updateOrganizationData } = organizationSlice.actions;

export const organizationReducer = organizationSlice.reducer;
