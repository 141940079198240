import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { type UserData } from "@services/userService";

export const initialState: UserData = {
  dateFormat: "DD/MM/YYYY",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  isPhoneVerified: false,
  isEmailVerified: false,
  id: "",
  hasStartedTutorial: false,
  name: "",
  organizationIds: [],
  picture: "",
  otpContact: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, { payload }: PayloadAction<Partial<UserData>>) => ({ ...state, ...payload }),
  },
});

export const { updateUser } = userSlice.actions;

export const userReducer = userSlice.reducer;
