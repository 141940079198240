import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface TutorialState {
  currentStep: number;
  isSkipModalShown: boolean;
}

export const initialState: TutorialState = {
  currentStep: 0,
  isSkipModalShown: false,
};

export const tutorialSlice = createSlice({
  name: "tutorial",
  initialState,
  reducers: {
    setTutorialStep: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      currentStep: payload,
    }),
    setIsSkipModalShown: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isSkipModalShown: payload,
    }),
  },
});

export const { setTutorialStep, setIsSkipModalShown } = tutorialSlice.actions;

export const tutorialReducer = tutorialSlice.reducer;
