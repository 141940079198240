import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  isLoggedIn: boolean;
  loginErrorMessage: string | undefined;
  token: string | undefined;
}

export const initialState: AuthState = {
  token: undefined,
  isLoggedIn: false,
  loginErrorMessage: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    saveToken: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      token: payload,
    }),
    loginSuccess: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      isLoggedIn: true,
      loginErrorMessage: undefined,
      token: payload,
    }),
    loginError: (_, { payload }: PayloadAction<string>) => ({
      ...initialState,
      loginErrorMessage: payload,
    }),
    logout: () => ({
      ...initialState,
    }),
  },
});

export const { saveToken, loginSuccess, loginError, logout } = authSlice.actions;
export const authReducer = authSlice.reducer;
