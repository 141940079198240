import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { IconName } from "@technis/ui";

import { BreadcrumbRouteName } from "@common/enums/breadcrumbs";

import { translation } from "@lang/translation";

type InitialState = {
  [key in BreadcrumbRouteName]: {
    icon?: IconName | string;
    text: string;
  };
};

export const TRANSLATION_KEY = "translate:";

export const initialState: InitialState = {
  [BreadcrumbRouteName.INSTALLATION__AIR_QUALITY]: {
    icon: IconName.WIND,
    text: `${TRANSLATION_KEY}${translation.installations.airQualityTitle}`,
  },
  [BreadcrumbRouteName.INSTALLATION__COUNTING]: {
    icon: IconName.COUNTING,
    text: `${TRANSLATION_KEY}${translation.installations.countingTitle}`,
  },
  [BreadcrumbRouteName.INSTALLATION__AIR_QUALITY__DETAILS]: {
    text: "",
  },
  [BreadcrumbRouteName.INSTALLATION__COUNTING__DETAILS]: {
    text: "",
  },
  [BreadcrumbRouteName.HOME]: {
    text: `${TRANSLATION_KEY}${translation.navigation.homePage}`,
    icon: IconName.HOUSE,
  },
  [BreadcrumbRouteName.CUSTOM_PAGE]: {
    text: "",
    icon: "",
  },
  [BreadcrumbRouteName.HELP_CENTER]: {
    text: `${TRANSLATION_KEY}${translation.common.helpCenter}`,
    icon: IconName.CIRCLE_QUESTION_MARK_EMPTY,
  },
  [BreadcrumbRouteName.SETTINGS]: {
    text: `${TRANSLATION_KEY}${translation.common.settings}`,
    icon: IconName.GEAR,
  },
  [BreadcrumbRouteName.SETTINGS__ALERTS]: {
    text: `${TRANSLATION_KEY}${translation.settings.alerts.title}`,
    icon: "",
  },
  [BreadcrumbRouteName.SETTINGS__ALERTS__NEW]: {
    text: `${TRANSLATION_KEY}${translation.settings.alerts.new}`,
    icon: "",
  },
  [BreadcrumbRouteName.SETTINGS__ALERTS__UPDATE]: {
    text: "",
    icon: "",
  },
  [BreadcrumbRouteName.USER_PROFILE]: {
    text: `${TRANSLATION_KEY}${translation.userProfile.breadcrumb}`,
  },
  [BreadcrumbRouteName.USER_PROFILE_GENERAL]: {
    text: `${TRANSLATION_KEY}${translation.userProfile.general.tabName}`,
  },
  [BreadcrumbRouteName.USER_PROFILE_PREFERENCES]: {
    text: `${TRANSLATION_KEY}${translation.userProfile.preferences.tabName}`,
  },
  [BreadcrumbRouteName.USER_PROFILE_SECURITY]: {
    text: `${TRANSLATION_KEY}${translation.userProfile.security.tabName}`,
  },
  [BreadcrumbRouteName.SETTINGS_USER_MANAGEMENT]: {
    text: `${TRANSLATION_KEY}${translation.settings.userManagement.tabName}`,
    icon: "",
  },
  [BreadcrumbRouteName.SETTINGS_USER_MANAGEMENT_PROFILE]: {
    text: `${TRANSLATION_KEY}${translation.settings.userManagement.tabName}`,
    icon: "",
  },
  [BreadcrumbRouteName.EXPORT_DATA]: {
    text: `${TRANSLATION_KEY}${translation.navigation.exportPage}`,
    icon: IconName.FILE_CHART_COLUMN,
  },
  [BreadcrumbRouteName.SETTINGS]: {
    text: `${TRANSLATION_KEY}${translation.common.settings}`,
    icon: IconName.GEAR,
  },
  [BreadcrumbRouteName.SETTINGS_ORGANIZATION]: {
    text: `${TRANSLATION_KEY}${translation.settings.organization.tabName}`,
  },
};

export const breadcrumbsSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    updateBreadcrumb: (
      state,
      {
        payload,
      }: PayloadAction<{
        breadcrumbRouteName: BreadcrumbRouteName;
        icon?: IconName | string;
        text: string;
      }>,
    ) => {
      const { breadcrumbRouteName, icon, text } = payload;
      return {
        ...state,
        [breadcrumbRouteName]: {
          icon,
          text,
        },
      };
    },
  },
});

export const { updateBreadcrumb } = breadcrumbsSlice.actions;
export const breadcrumbsReducer = breadcrumbsSlice.reducer;
