import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { FilterType } from "@common/enums";
import { type DayFilterConditions, type TimespanFilterConditions } from "@common/interfaces/filters";

interface BaseGlobalFilter {
  selectedValue: string;
  pageId: string;
}

interface OtherGlobalFilter extends BaseGlobalFilter {
  type: FilterType.ENGAGEMENT | FilterType.GENDER;
}
export interface CategoryGlobalFilter extends BaseGlobalFilter {
  id: number;
  type: FilterType.CATEGORY;
}

export type PageGlobalFilter = OtherGlobalFilter | CategoryGlobalFilter;

const isCategoryGlobalFilter = (filter: PageGlobalFilter): filter is CategoryGlobalFilter => filter.type === FilterType.CATEGORY;

export interface GlobalFilterState {
  cachedZoneId: number;
  dayFilterConditions: DayFilterConditions[];
  pagesGlobalFilters: PageGlobalFilter[];
  selectedItemIndex: number;
  timespanFilterConditions: TimespanFilterConditions[];
}

export const initialState: GlobalFilterState = {
  dayFilterConditions: [],
  pagesGlobalFilters: [],
  timespanFilterConditions: [],
  selectedItemIndex: 0,
  cachedZoneId: 0,
};

export const globalFilterSlice = createSlice({
  name: "globalFilter",
  initialState,
  reducers: {
    saveFilterIndex: (state, { payload }: PayloadAction<PageGlobalFilter>) => {
      const { selectedValue, pageId, type } = payload;
      const isFilterCategoryFilter = isCategoryGlobalFilter(payload);

      const pageIndexToModify = state.pagesGlobalFilters.findIndex(
        (page) =>
          page.pageId === pageId &&
          page.type === type &&
          (isFilterCategoryFilter && isCategoryGlobalFilter(page) ? page.id === payload.id : true),
      );
      if (pageIndexToModify > -1) {
        return {
          ...state,
          pagesGlobalFilters: state.pagesGlobalFilters.map((page, index) =>
            index === pageIndexToModify ? { ...page, selectedValue } : page,
          ),
        };
      } else {
        return {
          ...state,
          pagesGlobalFilters: [
            ...state.pagesGlobalFilters,
            {
              pageId,
              type,
              selectedValue,
              ...(isFilterCategoryFilter && { id: payload.id }),
            } as PageGlobalFilter,
          ],
        };
      }
    },
    saveDateIndex: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      selectedItemIndex: payload,
    }),
    saveTimespanFilterConditions: (state, { payload }: PayloadAction<TimespanFilterConditions[]>) => ({
      ...state,
      timespanFilterConditions: payload,
    }),
    saveDayFilterConditions: (state, { payload }: PayloadAction<DayFilterConditions[]>) => ({
      ...state,
      dayFilterConditions: payload,
    }),
    saveZone: (state, { payload }: PayloadAction<{ zoneId: number }>) => ({
      ...state,
      cachedZoneId: payload.zoneId,
    }),
    clearGlobalFilters: () => ({ ...initialState }),
  },
});

export const { saveFilterIndex, saveDateIndex, saveZone, clearGlobalFilters, saveTimespanFilterConditions, saveDayFilterConditions } =
  globalFilterSlice.actions;
export const globalFilterReducer = globalFilterSlice.reducer;
