import { type Middleware } from "@reduxjs/toolkit";
import { HOUR_IN_SECONDS } from "@technis/shared";

import { api } from "@api/apollo";

import { removeFromLocalStorage } from "@common/helpers";
import { COOKIE_AUTH, COOKIE_AUTH_VIEW_AS_USER, COOKIE_DOMAIN, getCookie, removeCookie, setCookie } from "@common/helpers/cookie";

import { loginError, loginSuccess, logout, saveToken } from "./auth.slice";
import { clearGlobalFilters } from "../globalFilter/globalFilter.slice";

export const authMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if ([loginSuccess.type, saveToken.type].includes(action.type) && !getCookie(COOKIE_AUTH_VIEW_AS_USER)) {
      setCookie(COOKIE_AUTH, action.payload, { secure: true, maxAge: HOUR_IN_SECONDS * 10, domain: COOKIE_DOMAIN });
    }
    if ([logout.type, loginError.type].includes(action.type)) {
      dispatch(clearGlobalFilters());
      removeCookie(COOKIE_AUTH, { domain: COOKIE_DOMAIN });
      removeCookie(COOKIE_AUTH_VIEW_AS_USER, { domain: COOKIE_DOMAIN });
      api.client?.clearStore();
      removeFromLocalStorage("email");
    }

    return next(action);
  };
