import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
  creationDate?: number | null;
};

export const initialState: InitialState = {
  creationDate: null,
};

export const installationSlice = createSlice({
  name: "installation",
  initialState,
  reducers: {
    updateInstallationCreationDate: (
      state,
      {
        payload,
      }: PayloadAction<{
        creationDate?: number | null;
      }>,
    ) => {
      const { creationDate } = payload;
      state.creationDate = creationDate;
    },
  },
});

export const { updateInstallationCreationDate } = installationSlice.actions;

export const installationReducer = installationSlice.reducer;
