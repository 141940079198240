import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { DEFAULT_VALIDATION_ORDER } from "@common/constants/validation/validationOrder";

import { type ValidationStatusItem } from "@components/common/ValidationWrapper/InputValidationWrapper/InputValidationWrapper";

export interface FormValidationInitialState {
  firstInvalidFieldOrder: number;
  validatedFields: Record<number, boolean>;
  validationFields: Record<number, boolean>;
}

export const initialState: FormValidationInitialState = {
  validationFields: {},
  validatedFields: {},
  firstInvalidFieldOrder: DEFAULT_VALIDATION_ORDER,
};

export const formValidationSlice = createSlice({
  name: "formValidation",
  initialState,
  reducers: {
    updateMultipleValidationStates: (state, { payload: validationItems }: PayloadAction<ValidationStatusItem[]>) => {
      const validationFields = Object.fromEntries(validationItems.map(({ validationOrder, isInvalid }) => [validationOrder, isInvalid]));
      return {
        ...state,
        validationFields: {
          ...state.validationFields,
          ...validationFields,
        },
      };
    },
    updateValidationState: (state, { payload }: PayloadAction<ValidationStatusItem>) => {
      const { validationOrder, isInvalid } = payload;

      return {
        ...state,
        validationFields: {
          ...state.validationFields,
          [validationOrder]: isInvalid,
        },
      };
    },
    checkValidationState: (state) => {
      const [firstInvalidFieldOrder] = Object.entries(state.validationFields)
        .sort(([previousOrder], [order]) => Number(previousOrder) - Number(order))
        .find(([, isInvalid]) => isInvalid) || [DEFAULT_VALIDATION_ORDER];

      return {
        ...state,
        firstInvalidFieldOrder: Number(firstInvalidFieldOrder),
        validatedFields: state.validationFields,
      };
    },
    clearFirstInvalidFieldOrder: (state) => ({
      ...state,
      firstInvalidFieldOrder: initialState.firstInvalidFieldOrder,
    }),
    clearValidationErrorsByOrders: (state, { payload }: PayloadAction<{ firstStep: number; lastStep: number }>) => {
      const { lastStep, firstStep } = payload;
      const ordersCount = 1 + lastStep - firstStep;

      const validationOrders = Array.from({ length: ordersCount }, () => null).map((_, index) => firstStep + index);

      const filterOrders = (fields: Record<number, boolean>): { [key: string]: boolean } => {
        const filteredEntries = Object.entries(fields).filter(([validationOrder]) => !validationOrders.includes(Number(validationOrder)));
        return Object.fromEntries(filteredEntries);
      };

      return {
        ...state,
        validationFields: filterOrders(state.validationFields),
        validatedFields: filterOrders(state.validatedFields),
      };
    },
    clearValidationErrors: () => initialState,
  },
});

export const {
  updateMultipleValidationStates,
  updateValidationState,
  clearValidationErrors,
  checkValidationState,
  clearFirstInvalidFieldOrder,
  clearValidationErrorsByOrders,
} = formValidationSlice.actions;
export const formValidationReducer = formValidationSlice.reducer;
