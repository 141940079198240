import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface InitialState {
  isFiltersShown: boolean;
}

export const chosenFiltersState: InitialState = {
  isFiltersShown: false,
};

export const chosenFilters = createSlice({
  name: "chosenFilters",
  initialState: chosenFiltersState,
  reducers: {
    updatedIsFiltersShown: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isFiltersShown: payload,
    }),
  },
});

export const { updatedIsFiltersShown } = chosenFilters.actions;
export const chosenFiltersReducer = chosenFilters.reducer;
